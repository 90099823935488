import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { FormProfanoPagePathParams } from "../FormProfanoPage";
import api from "../../../services/api";
import Loading from "../../../components/Loading";

const SignDocumentPage: React.FC = () => {
  const [displaySignatureCanvas, setDisplaySignatureCanvas] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [pdfObjectURL, setPdfObjectURL] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);

  const { token } = useParams<FormProfanoPagePathParams>();

  const getDeclaracaoSubsistencia = async () => {
    const isMobile = false;
    const downloadUrl = `/pdf/download/subsistencia/${token}?isMobile=${isMobile}`;
    try {
      const response = await api.get(downloadUrl, {
        responseType: "blob",
      });

      setPdfBlob(new Blob([response.data], { type: "application/pdf" }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Erro ao buscar o documento:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDeclaracaoSubsistencia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pdfBlob) {
      // eslint-disable-next-line no-console
      setPdfObjectURL(URL.createObjectURL(pdfBlob));
    }
  }, [pdfBlob]);

  useEffect(() => {
    return () => {
      if (pdfObjectURL) {
        URL.revokeObjectURL(pdfObjectURL);
      }
    };
  }, [pdfObjectURL]);

  const signatureDialogHeader = useCallback(() => {
    return <h1 className="default-dialog-header">Assine aqui</h1>;
  }, []);

  const onHideSignatureDialog = () => {
    setDisplaySignatureCanvas(false);
  };

  const handleConfirmSignature = async () => {
    if (sigCanvasRef.current) {
      const signatureDataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
      localStorage.setItem("@Assinado", "true");

      interface Body {
        assinatura: string;
        tipo: string;
        isMobile: boolean;
        situacao: string;
      }

      const body: Body = {
        assinatura: signatureDataUrl,
        tipo: "DECLARACAO_SUBSISTENCIA",
        situacao: "SUBMETIDO",
        isMobile: false,
      };

      try {
        setLoading(true);

        const url = `/formularios/profano/assinatura?token=${token}`;

        const response = await api.post(url, body);
        setLoading(false);
        return response;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Erro ao enviar a requisição:", error);
        setLoading(false);
      } finally {
        setDisplaySignatureCanvas(false);
        window.close();
      }
    }
    return null;
  };

  const handleClearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };

  const signatureDialogFooter = () => {
    return (
      <div className="digital-signature-footer">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={onHideSignatureDialog}
          className="p-button-text"
          style={{ marginRight: 0, height: 32 }}
        />

        <Button
          label="Limpar"
          icon="pi pi-sync"
          onClick={handleClearSignature}
          style={{ marginRight: 0, height: 32 }}
        />

        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={handleConfirmSignature}
          style={{ marginRight: 0, height: 32 }}
          autoFocus
        />
      </div>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
  }, [pdfObjectURL]);

  if (loading) {
    return <Loading isLoading />;
  }

  return (
    <SimpleEntityPage showTopBar showListHeader hidePagePadding>
      <Dialog
        header={signatureDialogHeader}
        visible={displaySignatureCanvas}
        modal
        footer={signatureDialogFooter()}
        onHide={onHideSignatureDialog}
        className="confirm-dialog-container"
        closable
        style={{ maxWidth: "600px", width: "100%" }}
      >
        <div className="signature-container">
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{ height: 300, className: "sigCanvas" }}
            backgroundColor="white"
          />
        </div>
      </Dialog>
      <div style={{ width: "100%", textAlign: "center", padding: "0px 22px" }}>
        <Button
          label="Assinar Documento"
          onClick={(event) => {
            event.preventDefault();
            setDisplaySignatureCanvas(true);
          }}
          style={{ maxWidth: "500px", width: "100%", alignSelf: "center" }}
          autoFocus
        />
      </div>
      {pdfObjectURL && (
        <iframe src={pdfObjectURL} style={{ width: "100%", height: "100%", marginTop: "20px" }} title="Documento PDF" />
      )}
    </SimpleEntityPage>
  );
};

export default SignDocumentPage;
