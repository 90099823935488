import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar } from "primereact/avatar";
import _ from "lodash";
import { Button } from "primereact/button";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { ResponseLogin, useAuth } from "../../hooks/auth";
import SimpleButton from "../SimpleButton";
import { getFirstCharactersFirstWord, getFirstCharactersSecondWord } from "../../utils/getFirstCharacters";
import { grausMap } from "../IrmaoComponent";
import CustomVerticalFormField from "../CustomVerticalFormField";
import api from "../../services/api";
import { useError } from "../../hooks/error";
import { trocarLojaErrors } from "../../errors/trocar-loja";
import { useToast } from "../../hooks/toast";
import RolesEnum from "../../hooks/rolesEnum/rolesEnum";

interface Props {
  logoutCommand: () => void;
}

interface LodgeOptions {
  uuid: string;
  numero: string;
  nome: string;
  tituloSigla: string;
}

const MenuLogOut: React.FC<Props> = ({ logoutCommand }) => {
  const [showChangeLodgeDialog, setShowChangeLodgeDialog] = useState(false);

  const [hasOptions, setHasOptions] = useState(false);
  const [newLodge, setNewLodge] = useState({} as LodgeOptions);
  const [lodgeOptions, setLodgeOptions] = useState<LodgeOptions[]>([]);

  const { showToast } = useToast();
  const { handleError } = useError();
  const { user, irmao, loja, potencia, usuario, updateUserInfo, hasAnyAuthority } = useAuth();
  let firstCharacterFirstName = "";
  let firstCharacterLastName = "";

  const firstName = user.nome.split(" ")[0];
  const charactersFirstName = getFirstCharactersFirstWord(user.nome);
  const charactersLastName = getFirstCharactersSecondWord(user.nome);

  if (charactersLastName && charactersFirstName) {
    [firstCharacterFirstName] = charactersFirstName;
    [firstCharacterLastName] = charactersLastName;
  }

  const hideChangeLodgeDialog = () => {
    setShowChangeLodgeDialog(false);
    setNewLodge({} as LodgeOptions);
  };

  const handleChangeLodgeCallback = useCallback(() => {
    setShowChangeLodgeDialog(true);
  }, []);

  const headerChangeLodge = useMemo(() => {
    return <span style={{ marginRight: "20px" }}>Deseja trocar de loja?</span>;
  }, []);

  const handleConfirmChange = useCallback(() => {
    api
      .put<ResponseLogin>("loja/trocar", { lojaUuid: newLodge.uuid })
      .then(({ data, headers }) => {
        updateUserInfo(data, headers, data.irmaoEmail);
        showToast({ type: "success", title: "Sucesso.", description: `Sua loja atual é: ${data.lojaNome}` });
        hideChangeLodgeDialog();
        window.location.reload();
      })
      .catch((err) => {
        handleError({ error: err, action: "trocar de loja", knownErrors: trocarLojaErrors });
      });
  }, [showToast, handleError, newLodge?.uuid, updateUserInfo]);

  const footerChangeLodge = useMemo(
    () => (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelar"
          onClick={hideChangeLodgeDialog}
          className="p-button-text text-color-branco-cinza"
        />
        <Button
          disabled={_.isEmpty(newLodge)}
          iconPos="right"
          icon="pi pi-check"
          label="Trocar de loja"
          onClick={handleConfirmChange}
        />
      </>
    ),
    [handleConfirmChange, newLodge]
  );

  const lodgeItemTemplate = useCallback((option: LodgeOptions, options?: DropdownProps) => {
    return (
      <div className="p-d-flex p-ai-center">
        {option ? (
          <span>
            {option.tituloSigla} {option.nome} nº {option.numero}
          </span>
        ) : (
          <span>{options?.placeholder}</span>
        )}
      </div>
    );
  }, []);

  useEffect(() => {
    if (hasAnyAuthority(RolesEnum.ROLE_TROCAR_LOJA)) {
      api
        .get<LodgeOptions[]>("loja/disponivel-troca")
        .then(({ data }) => {
          setHasOptions(true);
          setLodgeOptions(data);
        })
        .catch(() => {
          setHasOptions(false);
        });
    }
  }, [hasAnyAuthority]);

  return (
    <>
      <Dialog
        visible={showChangeLodgeDialog}
        onHide={hideChangeLodgeDialog}
        header={headerChangeLodge}
        footer={footerChangeLodge}
      >
        <CustomVerticalFormField
          iconMaterial="manage_accounts"
          htmlForDescription="dtLimite"
          description="Selecione para qual loja deseja trocar:"
          hideErrorMessage
          inputBody={
            <Dropdown
              value={newLodge}
              options={lodgeOptions}
              dataKey="uuid"
              optionLabel="nome"
              placeholder="Selecione a loja"
              onChange={(e) => {
                setNewLodge(e.value);
              }}
              valueTemplate={lodgeItemTemplate}
              itemTemplate={lodgeItemTemplate}
              filter
              filterBy="nome"
              emptyMessage="Nenhum loja encontrada"
              emptyFilterMessage="Nenhum loja encontrada"
              style={{
                width: "100%",
              }}
            />
          }
        />
      </Dialog>
      <div className="menu-logout-container">
        {!_.isEmpty(irmao) && !_.isEmpty(loja) && irmao && loja && (
          <>
            <div className="p-mb-3 p-d-flex p-ai-center irmao-container">
              <Avatar
                label={irmao.foto ? undefined : firstCharacterFirstName}
                image={irmao.foto || undefined}
                className="p-mr-3 irmao-avatar avatar-container"
                size="large"
                shape="circle"
              />
              <div className="p-d-flex p-flex-column">
                <span>
                  {firstName} {firstCharacterLastName}.
                </span>
                <span className="p-mt-1 menu-logout-grau-irmao">{grausMap[irmao.grau].label}</span>
              </div>
            </div>
            <div className="p-mb-2 p-d-flex p-ai-center">
              <div className="irmao-container">
                <Avatar
                  label={loja.foto ? undefined : loja.nome[0]}
                  image={loja.foto || undefined}
                  className="p-mr-3 irmao-avatar avatar-container"
                  size="large"
                  shape="circle"
                />
              </div>
              <div style={{ lineHeight: 1.8 }}>Loja {loja.nome}</div>
            </div>

            {hasAnyAuthority(RolesEnum.ROLE_TROCAR_LOJA) && hasOptions && (
              <Button className="p-d-flex p-jc-center p-ai-center" onClick={handleChangeLodgeCallback}>
                <span className="material-symbols-outlined svg-icon">manage_accounts</span>
                <div>Trocar de loja</div>
              </Button>
            )}

            <hr className="p-my-3 linha-horizontal" />
            <SimpleButton command={() => logoutCommand()} label="Sair" icon="pi pi-sign-out" />
          </>
        )}
        {!_.isEmpty(potencia) && !_.isEmpty(usuario) && potencia && usuario && (
          <>
            <div className="p-mb-3 p-d-flex p-ai-center irmao-container">
              <Avatar
                label={firstCharacterFirstName}
                className="p-mr-3 irmao-avatar avatar-container"
                size="large"
                shape="circle"
              />
              <div className="p-d-flex p-flex-column">
                <span>
                  {firstName} {firstCharacterLastName}.
                </span>
                {/* <span className="p-mt-1 menu-logout-grau-irmao">{grausMap[irmao.grau].label}</span> */}
              </div>
            </div>
            <div className="p-mb-2 p-d-flex p-ai-center">
              <div className="irmao-container">
                <Avatar
                  label={potencia.logo ? undefined : potencia.nome[0]}
                  image={potencia.logo || undefined}
                  className="p-mr-3 irmao-avatar avatar-container"
                  size="large"
                  shape="circle"
                />
              </div>
              <div style={{ lineHeight: 1.8 }}>{potencia.nome}</div>
            </div>
            <hr className="p-my-3 linha-horizontal" />
            <SimpleButton command={() => logoutCommand()} label="Sair" icon="pi pi-sign-out" />
          </>
        )}
      </div>
    </>
  );
};

export default MenuLogOut;
