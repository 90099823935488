import { IAppError } from "./AppErrorInterfaces";

export const trocarLojaErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    title: "Alteração inválida na URL.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Usuário não encontrado",
    title: "Usuário não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Irmão não encontrado",
    title: "Irmão não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Loja não encontrada",
    title: "Loja não encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
];
