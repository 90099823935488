type RemidoValue = "remido" | "not-remido";

export interface RemidoElement<T> {
  label: string;
  value: T;
}
type RemidoMap = {
  [name in RemidoValue]: RemidoElement<name>;
};

export const remidoMap: RemidoMap = {
  remido: {
    label: "Remidos",
    value: "remido",
  },
  "not-remido": {
    label: "Não remidos",
    value: "not-remido",
  },
};
