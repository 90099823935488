/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";

import { confirmDialog } from "primereact/confirmdialog";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import _ from "lodash";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { irmaoErrors } from "../../../errors/irmao";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import IrmaoComponent, { GrauElement, grausMap } from "../../../components/IrmaoComponent";
import IrmaoSituacaoComponent, { SituacaoIrmaoElement, situacoesMap } from "../../../components/IrmaoSituacaoComponent";
import { convertDateToStringBR } from "../../../utils/convertDates";
import { useError } from "../../../hooks/error";
import { formatStringToCelular } from "../../../utils/formatStringToCelular";
import { openUrlNewTab } from "../../../utils/url";
import { RemidoElement, remidoMap } from "../types";

export type Grau = "APRENDIZ" | "COMPANHEIRO" | "MESTRE" | "MESTRE_INSTALADO";
export type SituacaoIrmao =
  | "REGULAR"
  | "IRREGULAR"
  // | "REMIDO"
  | "FALECIDO"
  | "DESLIGADO"
  | "EX_OFICIO"
  | "ADORMECIDO"
  | "SUSPENSO";

export interface Irmao {
  uuid: string;
  nome: string;
  apelido?: string;
  grau?: Grau;
  situacao: SituacaoIrmao;
  email?: string;
  celular?: string;
  dtNascimento?: number | string;
  dtNascimentoStr?: string;
  cim: string;
  foto?: string;
  isRemido?: boolean;
}

const IrmaoPage: React.FC = () => {
  const PATH_DECLARACAO_VONTADE = "/pdf/declaracaoVontade/";

  const grauFilterOptions: GrauElement[] = useMemo((): GrauElement[] => {
    return [grausMap.APRENDIZ, grausMap.COMPANHEIRO, grausMap.MESTRE, grausMap.MESTRE_INSTALADO];
  }, []);
  const [grauFilter, setGrauFilter] = useState<GrauElement[]>([]);

  const remidoFilterOptions = useMemo<RemidoElement<string>[]>(() => Object.values(remidoMap), []);
  const [remidoFilter, setRemidoFilter] = useState<string[]>();

  const situacaoFilterOptions: SituacaoIrmaoElement[] = useMemo((): SituacaoIrmaoElement[] => {
    return [
      situacoesMap.REGULAR,
      situacoesMap.IRREGULAR,
      // situacoesMap.REMIDO,
      situacoesMap.EX_OFICIO,
      situacoesMap.ADORMECIDO,
      situacoesMap.DESLIGADO,
      situacoesMap.FALECIDO,
      situacoesMap.SUSPENSO,
    ];
  }, []);
  const [situacaoFilter, setSituacaoFilter] = useState<SituacaoIrmaoElement[]>([]);

  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [irmaos, setIrmaos] = useState<Irmao[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const { handleError } = useError();
  const history = useHistory();

  const onFilterChange = useCallback((selectedList) => {
    dt.current?.filter(selectedList, "situacao", "custom");
    setSituacaoFilter(selectedList);
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Irmao[]>(`irmaos`)
      .then(({ data }) => {
        data.forEach((irmao) => {
          if (irmao.dtNascimento) {
            // eslint-disable-next-line no-param-reassign
            irmao.dtNascimentoStr = convertDateToStringBR(irmao.dtNascimento);
          }
        });
        setIrmaos(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar irmãos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    onFilterChange([situacoesMap.REGULAR, situacoesMap.IRREGULAR]);
  }, [onFilterChange, loading]);

  const onDeleteIrmao = useCallback(
    (data: Irmao) => {
      api
        .delete(`irmaos/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O irmão foi excluído" });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "excluir irmão", knownErrors: irmaoErrors });
        });
    },
    [handleError, loadData, showToast]
  );

  const excluirIrmao = useCallback(
    (data: Irmao) => {
      confirmDialog({
        message: "Após a exclusão o item não poderá ser recuperado.",
        header: "Excluir irmão?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Excluir",
        accept: () => onDeleteIrmao(data),
        reject: () => {},
      });
    },
    [onDeleteIrmao]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Irmao) => {
      return (
        <>
          <Button
            type="button"
            className=" balandrau-action-button "
            icon="pi pi-file-pdf"
            onClick={() => {
              openUrlNewTab(`${PATH_DECLARACAO_VONTADE}${data.uuid}`);
            }}
          />
          <Button
            type="button"
            className="p-ml-1 p-row-editor-init p-link "
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/irmao/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-1 balandrau-exclude-button "
            icon="pi pi-trash"
            onClick={() => {
              excluirIrmao(data);
            }}
          />
        </>
      );
    },
    [excluirIrmao, history]
  );
  const dtNascimentoBodyTemplate = useCallback((rowData: Irmao) => {
    return rowData.dtNascimento
      ? new Date(rowData.dtNascimento).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      : "";
  }, []);

  const nomeBodyTemplate = useCallback((rowData: Irmao) => {
    return <IrmaoComponent nome={rowData.nome} apelido={rowData.apelido} foto={rowData.foto} />;
  }, []);

  const situacaoIrmaoBodyTemplate = useCallback((rowData: Irmao) => {
    return <IrmaoSituacaoComponent situacao={rowData.situacao} />;
  }, []);

  const grauBodyTemplate = useCallback((rowData: Irmao) => {
    return rowData.grau ? `${grausMap[rowData.grau].nivelStr}` : "";
  }, []);

  const filterByGrau = useCallback((value: Grau, filter: GrauElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.grau === value).length > 0;
    return false;
  }, []);

  const celularBodyTemplate = useCallback(({ celular }: Irmao) => {
    if (celular) return formatStringToCelular(celular);
    return "";
  }, []);

  const filterBySituacao = useCallback((value: SituacaoIrmao, filter: SituacaoIrmaoElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.situacao === value).length > 0;
    return false;
  }, []);

  const columnsIrmaos = useMemo(
    (): ColumnProps[] => [
      {
        field: "cim",
        header: "CIM",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "7%" },
        className: "p-text-nowrap p-text-truncate",
      },

      {
        field: "nome",
        header: "Nome",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        body: nomeBodyTemplate,
      },
      {
        field: "grau",
        header: "Grau",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "8%" },
        body: grauBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterByGrau(value, filter),
      },

      {
        field: "situacao",
        header: "Situação",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: "10%" },
        className: "",
        body: situacaoIrmaoBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterBySituacao(value, filter),
      },
      {
        field: "dtNascimentoStr",
        sortField: "dtNascimento",
        header: "Nascimento",
        sortable: true,
        align: "center",
        style: { align: "center", verticalAlign: "middle", width: "12%" },
        body: dtNascimentoBodyTemplate,
        className: " p-text-nowrap p-text-truncate",
      },
      {
        field: "celular",
        header: "Celular",
        align: "center",
        style: { verticalAlign: "middle", width: "12%" },
        className: " p-text-nowrap p-text-truncate",
        body: celularBodyTemplate,
      },
      {
        field: "email",
        header: "E-mail",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "18%" },
        className: "p-text-nowrap p-text-truncate",
      },

      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "130px", verticalAlign: "middle" },
      },
    ],
    [
      celularBodyTemplate,
      dtNascimentoBodyTemplate,
      editDeleteButtonsBodyTemplate,
      filterByGrau,
      filterBySituacao,
      grauBodyTemplate,
      nomeBodyTemplate,
      situacaoIrmaoBodyTemplate,
    ]
  );

  const grausFilterBodyTemplate = (
    <MultiSelect
      className="p-ml-2"
      showClear
      dataKey="grau"
      optionLabel="nivelStr"
      options={grauFilterOptions}
      value={grauFilter}
      onChange={(e) => {
        dt.current?.filter(e.value, "grau", "custom");
        setGrauFilter(e.target.value);
      }}
      placeholder="Filtrar por grau"
      selectedItemsLabel="{0} graus selecionados"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhum grau encontrado"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const remidoFilterBodyTemplate = (
    <MultiSelect
      showClear
      dataKey="value"
      optionLabel="label"
      options={remidoFilterOptions}
      value={remidoFilter}
      onChange={(e) => {
        let filterBy;
        if (e.value.length !== 2)
          if (e.value[0] === "remido") filterBy = true;
          else if (e.value[0] === "not-remido") filterBy = false;

        dt.current?.filter(filterBy, "isRemido", "equals");
        setRemidoFilter(e.target.value);
      }}
      placeholder="Filtrar remidos"
      selectedItemsLabel="{0} situações selecionadas"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhuma opção encontrada"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const situacaoFilterBodyTemplate = (
    <MultiSelect
      showClear
      dataKey="situacao"
      optionLabel="label"
      options={situacaoFilterOptions}
      value={situacaoFilter}
      onChange={(e) => {
        onFilterChange(e.target.value);
      }}
      placeholder="Filtrar por situação"
      selectedItemsLabel="{0} situações selecionadas"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhuma situação encontrada"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const datatableFilters = (
    <>
      {remidoFilterBodyTemplate}
      {situacaoFilterBodyTemplate}
      {grausFilterBodyTemplate}
    </>
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Novo irmão"
      onNewButtonCLick={() => {
        history.push("/irmao/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      listHeaderContentRight={datatableFilters}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={irmaos}
        columns={columnsIrmaos}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default IrmaoPage;
