import React, { useCallback, useEffect, useMemo, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { validate } from "email-validator";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { Dropdown } from "primereact/dropdown";

import { InputSwitch } from "primereact/inputswitch";

import FloatingSave from "../../../components/FloatingSave";
import { irmaoErrors } from "../../../errors/irmao";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import CustomFormField from "../../../components/CustomFormField";

import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { Grau, SituacaoIrmao } from "../IrmaoPage";
import { GrauElement, grausMap } from "../../../components/IrmaoComponent";
import { SituacaoIrmaoElement, situacoesMap } from "../../../components/IrmaoSituacaoComponent";
import { removeOffsetFromDate } from "../../../utils/convertDates";
import { formatDateToStringForSend } from "../../../utils/formatDateToStringForSend";
import { useError } from "../../../hooks/error";
import PreviewSelectAvatar from "../../../components/PreviewSelectAvatar";
import { enderecoErrors } from "../../../errors/endereco";
import { UF } from "../../FormProfano/FormProfanoPage/EtapaForm";

interface Params {
  id: string;
}

interface Endereco {
  cep?: string;
  logradouro?: string;
  numeroLogradouro?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
}

export interface IrmaoForm {
  // obrigatórios
  uuid?: string;
  nome: string;
  grau: Grau;
  situacao: SituacaoIrmao;

  // opcionais
  apelido?: string;
  email?: string;
  dtNascimento?: string;
  cim?: string;
  rg?: string;
  cpf?: string;
  celular?: string;
  profissao?: string;
  localTrabalho?: string;
  dtIniciacao?: string;
  dtElevacao?: string;
  dtExaltacao?: string;
  dtFalecimento?: string;
  foto?: string;
  isRemido?: boolean;

  endereco: Endereco[];
}

const IrmaoFormPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [irmaoInicial, setIrmaoInicial] = useState<IrmaoForm>({} as IrmaoForm);
  const grauOptions = useMemo(
    (): GrauElement[] => [grausMap.APRENDIZ, grausMap.COMPANHEIRO, grausMap.MESTRE, grausMap.MESTRE_INSTALADO],
    []
  );

  const situacaoIrmaoOptions = useMemo(
    (): SituacaoIrmaoElement[] => [
      situacoesMap.REGULAR,
      situacoesMap.IRREGULAR,
      situacoesMap.EX_OFICIO,
      situacoesMap.ADORMECIDO,
      situacoesMap.DESLIGADO,
      situacoesMap.FALECIDO,
    ],
    []
  );

  const [nome, setNome] = useState<string>("");
  const [apelido, setApelido] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [rg, setRg] = useState<string>("");
  const [dtNascimento, setDtNascimento] = useState<Date | Date[] | undefined>(undefined);
  const [dtNascimentoF, setDtNascimentoF] = useState<string | undefined>(undefined);
  const [profissao, setProfissao] = useState<string>("");
  const [localTrabalho, setLocalTrabalho] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [foto, setFoto] = useState<string | undefined>(undefined);

  const [cim, setCim] = useState<string>("");
  const [grau, setGrau] = useState<GrauElement>(grausMap.APRENDIZ);
  const [dtIniciacao, setDtIniciacao] = useState<Date | Date[] | undefined>(undefined);
  const [dtElevacao, setDtElevacao] = useState<Date | Date[] | undefined>(undefined);
  const [dtExaltacao, setDtExaltacao] = useState<Date | Date[] | undefined>(undefined);

  const [dtIniciacaoF, setDtIniciacaoF] = useState<string | undefined>(undefined);
  const [dtElevacaoF, setDtElevacaoF] = useState<string | undefined>(undefined);
  const [dtExaltacaoF, setDtExaltacaoF] = useState<string | undefined>(undefined);

  const [situacaoIrmao, setSituacaoIrmao] = useState<SituacaoIrmaoElement>(situacoesMap.REGULAR);
  const [dtFalecimento, setDtFalecimento] = useState<Date | Date[] | undefined>(undefined);

  const [remido, setRemido] = useState<boolean>(false);

  const [dtFalecimentoF, setDtFalecimentoF] = useState<string | undefined>(undefined);

  const [cep, setCep] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numeroLogradouro, setNumeroLogradouro] = useState<string>("");
  const [complemento, setComplemento] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [estado, setEstado] = useState<string>("");

  const [isDirty, setIsDirty] = useState(false);

  const { showToast } = useToast();
  const { handleError } = useError();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const { id } = useParams<Params>();
  const history = useHistory();

  const ufOptions: UF[] = useMemo(() => {
    return [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }, []);

  const replacePhoneNumber = useCallback((phoneNumber: string | undefined) => {
    if (phoneNumber)
      return phoneNumber.replaceAll("_", "").replace(" ", "").replace("(", "").replace(")", "").replace("-", "");
    return "";
  }, []);

  const replaceCep = useCallback((cepString: string | undefined) => {
    if (cepString) return cepString.replace(".", "").replace("-", "").replaceAll("_", "");
    return "";
  }, []);

  const replaceCPF = useCallback((cpfString: string | undefined) => {
    if (cpfString) return cpfString.replace("-", "").replaceAll(".", "").replaceAll("_", "");
    return "";
  }, []);

  const loadIrmao = useCallback(() => {
    setLoading(true);
    api
      .get<IrmaoForm>(`irmaos/${id}`)
      .then(({ data: irmao }) => {
        setIrmaoInicial({
          nome: irmao.nome ? irmao.nome : "",
          apelido: irmao.apelido ? irmao.apelido : "",
          cpf: irmao.cpf ? irmao.cpf : "",
          rg: irmao.rg ? irmao.rg : "",
          dtNascimento: irmao.dtNascimento ? irmao.dtNascimento : "",
          profissao: irmao.profissao ? irmao.profissao : "",
          localTrabalho: irmao.localTrabalho ? irmao.localTrabalho : "",
          email: irmao.email ? irmao.email : "",
          celular: irmao.celular ? irmao.celular : "",
          foto: irmao.foto,
          cim: irmao.cim ? irmao.cim : "",
          grau: grausMap[irmao.grau].grau,
          dtIniciacao: irmao.dtIniciacao ? irmao.dtElevacao : "",
          dtElevacao: irmao.dtElevacao ? irmao.dtElevacao : "",
          dtExaltacao: irmao.dtExaltacao ? irmao.dtExaltacao : "",
          situacao: situacoesMap[irmao.situacao].situacao,
          dtFalecimento: irmao.dtFalecimento ? irmao.dtFalecimento : "",
          isRemido: irmao.isRemido,
          endereco: [
            {
              cep: irmao.endereco.length > 0 ? irmao.endereco[0].cep : "",
              logradouro: irmao.endereco.length > 0 ? irmao.endereco[0].logradouro : "",
              numeroLogradouro: irmao.endereco.length > 0 ? irmao.endereco[0].numeroLogradouro : "",
              complemento: irmao.endereco.length > 0 ? irmao.endereco[0].complemento : "",
              bairro: irmao.endereco.length > 0 ? irmao.endereco[0].bairro : "",
              cidade: irmao.endereco.length > 0 ? irmao.endereco[0].cidade : "",
              estado: irmao.endereco.length > 0 ? irmao.endereco[0].estado : "",
            },
          ],
        });
        setNome(irmao.nome);
        setApelido(irmao.apelido || "");
        setCpf(irmao.cpf || "");
        setRg(irmao.rg || "");
        setDtNascimento(irmao.dtNascimento ? removeOffsetFromDate(new Date(irmao.dtNascimento)) : undefined);
        setDtNascimentoF(irmao.dtNascimento ? formatDateToStringForSend(irmao.dtNascimento) : undefined);

        setProfissao(irmao.profissao || "");
        setLocalTrabalho(irmao.localTrabalho || "");
        setEmail(irmao.email || "");
        setCelular(irmao.celular || "");
        setFoto(irmao.foto);
        setCim(irmao.cim || "");
        setGrau(grausMap[irmao.grau]);
        setRemido(!!irmao.isRemido);

        setDtIniciacao(irmao.dtIniciacao ? removeOffsetFromDate(new Date(irmao.dtIniciacao)) : undefined);
        setDtIniciacaoF(irmao.dtIniciacao ? formatDateToStringForSend(irmao.dtIniciacao) : undefined);

        setDtElevacao(irmao.dtElevacao ? removeOffsetFromDate(new Date(irmao.dtElevacao)) : undefined);
        setDtElevacaoF(irmao.dtElevacao ? formatDateToStringForSend(irmao.dtElevacao) : undefined);

        setDtExaltacao(irmao.dtExaltacao ? removeOffsetFromDate(new Date(irmao.dtExaltacao)) : undefined);
        setDtExaltacaoF(irmao.dtExaltacao ? formatDateToStringForSend(irmao.dtExaltacao) : undefined);

        setSituacaoIrmao(situacoesMap[irmao.situacao]);
        setDtFalecimento(irmao.dtFalecimento ? removeOffsetFromDate(new Date(irmao.dtFalecimento)) : undefined);
        setDtFalecimentoF(irmao.dtFalecimento ? formatDateToStringForSend(irmao.dtFalecimento) : undefined);

        if (irmao.endereco.length > 0) {
          const endereco = irmao.endereco[0];
          setCep(endereco.cep || "");
          setLogradouro(endereco.logradouro || "");
          setNumeroLogradouro(endereco.numeroLogradouro || "");
          setComplemento(endereco.complemento || "");
          setBairro(endereco.bairro || "");
          setCidade(endereco.cidade || "");
          setEstado(endereco.estado || "");
        } else {
          setCep("");
          setLogradouro("");
          setNumeroLogradouro("");
          setComplemento("");
          setBairro("");
          setCidade("");
          setEstado("");
        }
      })
      .catch((error) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o irmão",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [id, showToast]);

  const redefinirIrmao = useCallback(() => {
    setIsDirty(false);

    if (id === "novo") {
      // dados pessoais
      setNome("");
      setApelido("");
      setCpf("");
      setRg("");

      setDtNascimento([]);
      setDtNascimentoF(undefined);

      setProfissao("");
      setLocalTrabalho("");
      setEmail("");
      setCelular("");

      // marconaria
      setCim("");
      setGrau(grausMap.APRENDIZ);

      setDtIniciacao([]);
      setDtIniciacaoF(undefined);

      setDtElevacao([]);
      setDtElevacaoF("");

      setDtExaltacao([]);
      setDtExaltacaoF("");

      setSituacaoIrmao(situacoesMap.REGULAR);

      setDtFalecimento([]);
      setDtFalecimentoF("");

      // endereco
      setCep("");
      setLogradouro("");
      setNumeroLogradouro("");
      setComplemento("");
      setBairro("");
      setCidade("");
      setEstado("");
    } else {
      loadIrmao();
    }
  }, [id, loadIrmao]);

  const validarCampos = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(nome)) {
      errorsLocal.nome = "Este é um campo obrigatório";
    }

    if (!_.isEmpty(email)) {
      if (!validate(email)) {
        errorsLocal.email = "Insira um email válido ex.: exemplo@dominio.com";
      }
    }

    if (!_.isEmpty(cpf)) {
      if (!cpfValidator.isValid(cpf)) {
        errorsLocal.cpf = "Insira um cpf válido";
      }
    }

    if (!_.isEmpty(celular)) {
      if (replacePhoneNumber(celular).length !== 11) {
        errorsLocal.celular = "Insira um telefone válido";
      }
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [celular, cpf, email, nome, replacePhoneNumber]);

  const validacao = useCallback(() => {
    return (
      !_.isEmpty(nome.trim()) &&
      (!_.isEqual(irmaoInicial.nome, nome) ||
        !_.isEqual(irmaoInicial.apelido, apelido) ||
        !_.isEqual(irmaoInicial.cpf, cpf) ||
        !_.isEqual(irmaoInicial.rg, rg) ||
        !_.isEqual(irmaoInicial.dtNascimento, dtNascimentoF) ||
        !_.isEqual(irmaoInicial.profissao, profissao) ||
        !_.isEqual(irmaoInicial.localTrabalho, localTrabalho) ||
        !_.isEqual(irmaoInicial.email, email) ||
        !_.isEqual(irmaoInicial.celular, celular) ||
        !_.isEqual(irmaoInicial.cim, cim) ||
        !_.isEqual(irmaoInicial.grau, grau.grau) ||
        !_.isEqual(irmaoInicial.dtIniciacao, dtIniciacaoF) ||
        !_.isEqual(irmaoInicial.dtElevacao, dtElevacaoF) ||
        !_.isEqual(irmaoInicial.dtExaltacao, dtExaltacaoF) ||
        !_.isEqual(irmaoInicial.situacao, situacaoIrmao.situacao) ||
        // !_.isEqual(irmaoInicial.logradouro, situacaoIrmao.logradouro) ||
        !_.isEqual(irmaoInicial.dtFalecimento, dtFalecimentoF))
    );
  }, [
    nome,
    irmaoInicial,
    apelido,
    cpf,
    rg,
    dtNascimentoF,
    profissao,
    localTrabalho,
    email,
    celular,
    cim,
    grau.grau,
    dtIniciacaoF,
    dtElevacaoF,
    dtExaltacaoF,
    situacaoIrmao.situacao,
    dtFalecimentoF,
  ]);

  const salvarIrmao = useCallback(() => {
    const valid = validarCampos();

    if (valid) {
      if (id === "novo") {
        setLoadingSalvar(true);
        api
          .post("irmaos", {
            // idTurma: 2,
            nome,
            apelido,
            cpf: _.isEmpty(cpf) ? undefined : replaceCPF(cpf),
            rg,
            dtNascimento,
            profissao,
            localTrabalho,
            email,
            celular: replacePhoneNumber(celular),
            foto,
            cim,
            grau: grau.grau,
            dtIniciacao,
            dtElevacao,
            dtExaltacao,
            situacao: situacaoIrmao.situacao,
            dtFalecimento,
            logradouro: "",
            endereco: {
              cep: replaceCep(cep),
              logradouro,
              numeroLogradouro,
              complemento,
              bairro,
              cidade,
              estado,
            },
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "Um novo irmão foi criado." });
            history.push("/irmao");
          })
          .catch((err) => {
            handleError({ error: err, action: "criar irmão", knownErrors: irmaoErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        setLoadingSalvar(true);
        api
          .put(`irmaos/${id}`, {
            idTurma: 2,
            nome,
            apelido,
            cpf: _.isEmpty(cpf) ? undefined : replaceCPF(cpf),
            rg,
            dtNascimento,
            profissao,
            localTrabalho,
            email,
            celular: replacePhoneNumber(celular),
            foto,
            cim,
            grau: grau.grau,
            dtIniciacao,
            dtElevacao,
            dtExaltacao,
            situacao: situacaoIrmao.situacao,
            dtFalecimento,
            logradouro: "",
            endereco: {
              cep: replaceCep(cep),
              logradouro,
              numeroLogradouro,
              complemento,
              bairro,
              cidade,
              estado,
            },
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "O irmão foi editado e salvo." });
            history.push("/irmao");
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar irmão", knownErrors: irmaoErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    apelido,
    bairro,
    celular,
    cep,
    cidade,
    cim,
    complemento,
    cpf,
    dtElevacao,
    dtExaltacao,
    dtFalecimento,
    dtIniciacao,
    dtNascimento,
    email,
    estado,
    foto,
    grau.grau,
    handleError,
    history,
    id,
    localTrabalho,
    logradouro,
    nome,
    numeroLogradouro,
    profissao,
    replaceCPF,
    replaceCep,
    replacePhoneNumber,
    rg,
    showToast,
    situacaoIrmao.situacao,
    validarCampos,
  ]);

  useEffect(() => {
    if (id !== "novo") loadIrmao();
  }, [id, loadIrmao]);

  const excluirIrmao = useCallback(() => {
    api
      .delete(`irmaos/${id}`)
      .then(() => {
        showToast({ title: "Sucesso!", type: "success", description: "O irmão foi excluído" });
        history.push("/irmao");
      })
      .catch((err) => {
        handleError({ error: err, action: "excluir irmão", knownErrors: irmaoErrors });
      });
  }, [handleError, history, id, showToast]);

  const confirmExclusion = useCallback(() => {
    confirmDialog({
      message: "Após a exclusão o item não poderá ser recuperado.",
      header: "Excluir irmão?",
      icon: "pi pi-question-circle",
      focusOnShow: false,
      rejectLabel: "Manter",
      acceptLabel: "Excluir",
      accept: () => excluirIrmao(),
      reject: () => {},
    });
  }, [excluirIrmao]);

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const onFotoChange = useCallback(
    (e, newImage) => {
      const blob = new Blob([`${newImage}`], { type: newImage?.split(";")[0].replace("data:", "") });
      if (blob.type !== "image/png" && blob.type !== "image/jpeg" && blob.type !== "image/jpg") {
        showToast({
          type: "warn",
          title: "Extensão da imagem incorreta",
          description: `A imagem precisa ser do tipo .jpg, ou .jpeg, ou .png`,
        });
        return;
      }

      setFoto(newImage);
      if (!_.isEqual(irmaoInicial.foto, newImage)) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    },
    [showToast, irmaoInicial.foto]
  );
  const onFotoClear = useCallback(() => {
    setFoto(undefined);
    if (irmaoInicial.foto) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [irmaoInicial]);

  return (
    <SimpleEntityListPage
      showTopBar
      routeBack="/irmao"
      excludeCommand={id !== "novo" ? confirmExclusion : undefined}
      excludeLabel="Excluir irmão"
      isFormPage
      loading={loading}
    >
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="modelo-container">
        <TabPanel header="Dados Pessoais">
          <div className="p-grid p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomFormField
                  icon="pi pi-user"
                  htmlForDescription="nome"
                  description="Nome completo"
                  inputBody={
                    <InputText
                      id="nome"
                      value={nome}
                      type="text"
                      placeholder="Nome e sobrenome"
                      onChange={(e) => {
                        setNome(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.nome;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.nome, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={100}
                    />
                  }
                  required
                  errorMessage={errors.nome}
                />
                <CustomFormField
                  icon="pi pi-user-edit"
                  htmlForDescription="apelido"
                  description="Conhecido como"
                  inputBody={
                    <InputText
                      id="apelido"
                      placeholder="Como gostaria de ser chamado?"
                      value={apelido}
                      type="text"
                      onChange={(e) => {
                        setApelido(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.apelido;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.apelido, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={30}
                    />
                  }
                  errorMessage={errors.apelido}
                />
                <CustomFormField
                  icon="pi pi-id-card"
                  htmlForDescription="cpf"
                  description="CPF"
                  inputBody={
                    <InputMask
                      id="cpf"
                      value={cpf}
                      placeholder="XXX.XXX.XXX-XX"
                      mask="999.999.999-99"
                      onChange={(e) => {
                        setCpf(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.cpf;
                        setErrors(errorsLocal);

                        if (!_.isEqual(replaceCPF(irmaoInicial.cpf), replaceCPF(e.target.value))) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      autoClear={false}
                    />
                  }
                  // required
                  errorMessage={errors.cpf}
                />
                <CustomFormField
                  icon="pi pi-id-card"
                  htmlForDescription="rg"
                  description="Identidade (RG)"
                  inputBody={
                    <InputText
                      id="rg"
                      value={rg}
                      type="text"
                      onChange={(e) => {
                        setRg(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.rg;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.rg, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={20}
                    />
                  }
                  // required
                  errorMessage={errors.rg}
                />
                <CustomFormField
                  icon="pi pi-calendar"
                  htmlForDescription="nascimento"
                  description="Data de Nascimento "
                  inputBody={
                    <Calendar
                      id="navigatorstemplate"
                      placeholder="DD/MM/AAAA"
                      value={dtNascimento}
                      maxDate={new Date()}
                      onChange={(e) => {
                        setDtNascimento(e.value);
                        if (!_.isEqual(irmaoInicial.dtNascimento, formatDateToStringForSend(e.value?.toString()))) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      monthNavigator
                      yearNavigator
                      yearRange="1800:2050"
                      monthNavigatorTemplate={monthNavigatorTemplate}
                      yearNavigatorTemplate={yearNavigatorTemplate}
                      locale="pt-br"
                      showIcon
                    />
                  }
                  errorMessage={errors.nascimento}
                />
                <CustomFormField
                  icon="pi pi-briefcase"
                  htmlForDescription="profissao"
                  description="Profissão"
                  inputBody={
                    <InputText
                      id="profissao"
                      value={profissao}
                      type="text"
                      onChange={(e) => {
                        setProfissao(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.profissao;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.profissao, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={50}
                    />
                  }
                  // required
                  errorMessage={errors.profissao}
                />
                <CustomFormField
                  icon="pi pi-briefcase"
                  htmlForDescription="localTrabalho"
                  description="Local de Trabalho"
                  inputBody={
                    <InputText
                      id="localTrabalho"
                      value={localTrabalho}
                      type="text"
                      onChange={(e) => {
                        setLocalTrabalho(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.localTrabalho;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.localTrabalho, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={60}
                    />
                  }
                  // required
                  errorMessage={errors.localTrabalho}
                />
                <CustomFormField
                  icon="ba ba-email"
                  htmlForDescription="email"
                  description="E-mail"
                  inputBody={
                    <InputText
                      id="email"
                      value={email}
                      placeholder="Ex.: usuario@dominio.com"
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.email;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.email, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={100}
                    />
                  }
                  errorMessage={errors.email}
                />
                <CustomFormField
                  icon="pi pi-phone"
                  htmlForDescription="celular"
                  description="Celular"
                  inputBody={
                    <InputMask
                      id="celular"
                      placeholder="(XX) XXXXX-XXXX"
                      mask="(99) 99999-9999"
                      value={celular}
                      onChange={(e) => {
                        setCelular(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.celular;
                        setErrors(errorsLocal);
                        if (!_.isEqual(replacePhoneNumber(irmaoInicial.celular), replacePhoneNumber(e.target.value))) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      autoClear={false}
                    />
                  }
                  // required
                  errorMessage={errors.celular}
                />
              </div>
            </div>
            <div className="p-col-5 form-irmao-image-container">
              <PreviewSelectAvatar
                src={foto}
                onChange={onFotoChange}
                onClear={onFotoClear}
                maxFileSizeMB={10}
                onLargeFileSize={(fileSize, maxFileSize) => {
                  showToast({
                    type: "warn",
                    title: "Limite de tamanho do arquivo excedido",
                    description: `O arquivo selecionado possui ${fileSize}MB. O tamanho máximo permitido é de ${maxFileSize}MB.`,
                  });
                }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Maçonaria">
          <div className="p-grid flex-row-center p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomFormField
                  icon="pi pi-key"
                  htmlForDescription="cim"
                  description="CIM"
                  inputBody={
                    <InputText
                      id="cim"
                      value={cim}
                      type="text"
                      onChange={(e) => {
                        setCim(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.cim;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.cim, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxLength={5}
                    />
                  }
                  // required
                  errorMessage={errors.cim}
                />
                <CustomFormField
                  icon="pi pi-angle-double-up"
                  htmlForDescription="grau"
                  description="Grau"
                  inputBody={
                    <Dropdown
                      id="grau"
                      value={grau}
                      emptyMessage="Nenhum grau encontrado."
                      options={grauOptions}
                      onChange={(e) => {
                        setGrau(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.grau;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.grau, e.target.value.grau)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      itemTemplate={(item: GrauElement) => `${item.label} (${item.nivelStr})`}
                      valueTemplate={(item: GrauElement) => `${item.label} (${item.nivelStr})`}
                    />
                  }
                  required
                  errorMessage={errors.grau}
                />
                <CustomFormField
                  icon="pi pi-calendar"
                  htmlForDescription="dtIniciacao"
                  description="Data de Iniciação "
                  inputBody={
                    <Calendar
                      id="dtIniciacao"
                      placeholder="DD/MM/AAAA"
                      value={dtIniciacao}
                      maxDate={new Date()}
                      onChange={(e) => {
                        setDtIniciacao(e.value);
                        if (!_.isEqual(irmaoInicial.dtIniciacao, formatDateToStringForSend(e.value?.toString()))) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      monthNavigator
                      yearNavigator
                      yearRange="1800:2050"
                      monthNavigatorTemplate={monthNavigatorTemplate}
                      yearNavigatorTemplate={yearNavigatorTemplate}
                      locale="pt-br"
                      showIcon
                    />
                  }
                  errorMessage={errors.dtIniciacao}
                />
                {grau.grau !== "APRENDIZ" && (
                  <CustomFormField
                    icon="pi pi-calendar"
                    htmlForDescription="dtElevacao"
                    description="Data de Elevação "
                    inputBody={
                      <Calendar
                        id="dtElevacao"
                        placeholder="DD/MM/AAAA"
                        value={dtElevacao}
                        maxDate={new Date()}
                        onChange={(e) => {
                          setDtElevacao(e.value);
                          if (!_.isEqual(irmaoInicial.dtElevacao, formatDateToStringForSend(e.value?.toString()))) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        monthNavigator
                        yearNavigator
                        yearRange="1800:2050"
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        locale="pt-br"
                        showIcon
                      />
                    }
                    errorMessage={errors.dtElevacao}
                  />
                )}
                {grau.grau !== "APRENDIZ" && grau.grau !== "COMPANHEIRO" && (
                  <CustomFormField
                    icon="pi pi-calendar"
                    htmlForDescription="dtExaltacao"
                    description="Data de Exaltação "
                    inputBody={
                      <Calendar
                        id="dtExaltacao"
                        placeholder="DD/MM/AAAA"
                        value={dtExaltacao}
                        maxDate={new Date()}
                        onChange={(e) => {
                          setDtExaltacao(e.value);
                          if (!_.isEqual(irmaoInicial.dtExaltacao, formatDateToStringForSend(e.value?.toString()))) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        monthNavigator
                        yearNavigator
                        yearRange="1800:2050"
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        locale="pt-br"
                        showIcon
                      />
                    }
                    errorMessage={errors.dtExaltacao}
                  />
                )}
                <CustomFormField
                  icon="pi pi-tag"
                  htmlForDescription="situacaoIrmao"
                  description="Situação"
                  inputBody={
                    <Dropdown
                      id="situacaoIrmao"
                      value={situacaoIrmao}
                      emptyMessage="Nenhuma situação encontrada."
                      options={situacaoIrmaoOptions}
                      onChange={(e) => {
                        setSituacaoIrmao(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.situacaoIrmao;
                        setErrors(errorsLocal);
                        if (!_.isEqual(irmaoInicial.situacao, e.target.value.situacao)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                    />
                  }
                  required
                  errorMessage={errors.situacaoIrmao}
                />
                {situacaoIrmao.situacao === "FALECIDO" && (
                  <CustomFormField
                    icon="pi pi-calendar"
                    htmlForDescription="dtFalecimento"
                    description="Data de Falecimento "
                    inputBody={
                      <Calendar
                        id="dtFalecimento"
                        placeholder="DD/MM/AAAA"
                        value={dtFalecimento}
                        maxDate={new Date()}
                        onChange={(e) => {
                          setDtFalecimento(e.value);
                          if (!_.isEqual(irmaoInicial.dtFalecimento, formatDateToStringForSend(e.value?.toString()))) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        monthNavigator
                        yearNavigator
                        yearRange="1800:2050"
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        locale="pt-br"
                        showIcon
                      />
                    }
                    errorMessage={errors.dtFalecimento}
                  />
                )}
                <CustomFormField
                  // icon="fa-solid fa-handshack"
                  iconMaterial="handshake"
                  htmlForDescription="remido"
                  description="Irmão Remido?"
                  inputBody={
                    <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                      <InputSwitch
                        checked={remido}
                        disabled
                        onChange={(e) => {
                          setRemido(e.value);

                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.remido;
                          setErrors(errorsLocal);
                          if (!_.isEqual(irmaoInicial.isRemido, e.target.value)) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                      />
                      <span className="p-ml-2" style={{ userSelect: "none" }} id="remido">
                        {remido ? "Sim" : "Não"}
                      </span>
                    </div>
                  }
                  required
                  errorMessage={errors.situacaoIrmao}
                />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel header="Endereço">
          <div className="p-grid flex-row-center p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomFormField
                  icon="pi pi-map-marker"
                  htmlForDescription="cep"
                  description="CEP"
                  inputBody={
                    <div className="p-d-flex p-ai-center p-jc-center">
                      <InputMask
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        id="cep"
                        placeholder="XX.XXX-XXX"
                        value={cep}
                        type="text"
                        onChange={(e) => {
                          setCep(e.target.value);
                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.cep;
                          setErrors(errorsLocal);

                          if (irmaoInicial.endereco && irmaoInicial.endereco.length > 0) {
                            if (!_.isEqual(replaceCep(irmaoInicial.endereco[0].cep), replaceCep(e.target.value))) {
                              setIsDirty(true);
                            } else {
                              setIsDirty(false);
                            }
                          }
                        }}
                        mask="99.999-999"
                        autoClear={false}
                      />
                      <Button
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        icon="pi pi-search"
                        onClick={() => {
                          setIsDirty(true);

                          if (replaceCep(cep).length !== 8) {
                            const errorsLocal: { [campo: string]: string } = {};
                            errorsLocal.cep = "O campo cep deve conter 8 dígitos";
                            setErrors(errorsLocal);
                            return;
                          }

                          api
                            .post(`enderecos/consultar-cep`, { cep: replaceCep(cep) })
                            .then((res) => {
                              showToast({
                                title: "A consulta foi feita com sucesso.",
                                type: "success",
                                description: "Prossiga com o preenchimento dos dados.",
                              });
                              setLogradouro(res.data.logradouro);
                              setNumeroLogradouro("");
                              setComplemento(res.data.complemento);
                              setBairro(res.data.bairro);
                              setCidade(res.data.cidade);
                              setEstado(res.data.estado);
                            })
                            .catch((err) => {
                              handleError({ error: err, action: "consultar cep", knownErrors: enderecoErrors });
                            });
                        }}
                      />
                    </div>
                  }
                  errorMessage={errors.cep}
                />
                <CustomFormField
                  icon="pi pi-directions"
                  htmlForDescription="logradouro"
                  description="Logradouro"
                  inputBody={
                    <InputText
                      id="logradouro"
                      placeholder="Rua, Avenida, Beco, etc."
                      value={logradouro}
                      type="text"
                      onChange={(e) => {
                        setLogradouro(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.logradouro;
                        setErrors(errorsLocal);
                        setIsDirty(true);
                      }}
                      maxLength={50}
                    />
                  }
                  errorMessage={errors.logradouro}
                />
                <CustomFormField
                  icon="pi pi-home"
                  htmlForDescription="numeroLogradouro"
                  description="Número"
                  inputBody={
                    <InputText
                      id="numeroLogradouro"
                      value={numeroLogradouro}
                      type="text"
                      onChange={(e) => {
                        setNumeroLogradouro(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.numeroLogradouro;
                        setErrors(errorsLocal);
                        setIsDirty(true);
                      }}
                      maxLength={50}
                    />
                  }
                  errorMessage={errors.numeroLogradouro}
                />
                <CustomFormField
                  icon="pi pi-home"
                  htmlForDescription="complemento"
                  description="Complemento"
                  inputBody={
                    <InputText
                      id="complemento"
                      value={complemento}
                      type="text"
                      onChange={(e) => {
                        setComplemento(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.complemento;
                        setErrors(errorsLocal);
                        setIsDirty(true);
                      }}
                      maxLength={50}
                    />
                  }
                  errorMessage={errors.complemento}
                />
                <CustomFormField
                  icon="pi pi-compass"
                  htmlForDescription="bairro"
                  description="Bairro"
                  inputBody={
                    <InputText
                      id="bairro"
                      placeholder="Ex.: Petrópolis"
                      value={bairro}
                      type="text"
                      onChange={(e) => {
                        setBairro(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.bairro;
                        setErrors(errorsLocal);
                        setIsDirty(true);
                      }}
                      maxLength={50}
                    />
                  }
                  errorMessage={errors.bairro}
                />
                <CustomFormField
                  icon="pi pi-map"
                  htmlForDescription="cidade"
                  description="Cidade"
                  inputBody={
                    <InputText
                      id="cidade"
                      placeholder="Ex.: Manaus"
                      value={cidade}
                      type="text"
                      onChange={(e) => {
                        setCidade(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.cidade;
                        setErrors(errorsLocal);
                        setIsDirty(true);
                      }}
                      maxLength={50}
                    />
                  }
                  errorMessage={errors.cidade}
                />
                <CustomFormField
                  icon="pi pi-map"
                  htmlForDescription="estado"
                  description="Estado (UF)"
                  inputBody={
                    <Dropdown
                      id="estado"
                      placeholder="Ex.: AM"
                      value={estado}
                      emptyMessage="Nenhuma UF encontrada."
                      options={ufOptions}
                      onChange={(e) => {
                        setEstado(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.estado;
                        setErrors(errorsLocal);
                        setIsDirty(true);
                      }}
                    />
                  }
                  errorMessage={errors.estado}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div>
        {isDirty ? (
          <FloatingSave
            resetCommand={() => redefinirIrmao()}
            disabled={!validacao()}
            saveCommand={() => salvarIrmao()}
            loadingOnSave={loadingSalvar}
          />
        ) : null}
      </div>
    </SimpleEntityListPage>
  );
};

export default IrmaoFormPage;
