/* eslint-disable no-unused-vars */
enum RolesEnum {
  ROLE_TROCAR_LOJA = "ROLE_TROCAR_LOJA",
  ROLE_HISTORICO_GILAM = "ROLE_HISTORICO_GILAM",
  ROLE_ASAAS_SYNC_MOBILE = "ROLE_ASAAS_SYNC_MOBILE",
  ROLE_WEBHOOK_MANAGEMENT = "ROLE_WEBHOOK_MANAGEMENT",
  ROLE_LOJA = "ROLE_LOJA",
  ROLE_MOBILE_VERSION = "ROLE_MOBILE_VERSION",
  ROLE_CONVITE_MOBILE = "ROLE_CONVITE_MOBILE",
  ROLE_PROPONENTE = "ROLE_PROPONENTE",
  ROLE_RESET_SENHA = "ROLE_RESET_SENHA",
  ROLE_PODCAST = "ROLE_PODCAST",
  ROLE_PODCAST_WEB = "ROLE_PODCAST_WEB",
  ROLE_PROGRAMA = "ROLE_PROGRAMA",
  ROLE_DATAS_COMEMORATIVAS_WEB = "ROLE_DATAS_COMEMORATIVAS_WEB",
  ROLE_DATAS_COMEMORATIVAS = "ROLE_DATAS_COMEMORATIVAS",
  ROLE_NOMINATA = "ROLE_NOMINATA",
  ROLE_LEITOR = "ROLE_LEITOR",
  ROLE_EMPRESTIVO = "ROLE_EMPRESTIVO",
  ROLE_ACERVO = "ROLE_ACERVO",
  ROLE_CONFIRMACAO_PARTICIPACAO = "ROLE_CONFIRMACAO_PARTICIPACAO",
  ROLE_GLOMAM = "ROLE_GLOMAM",
  ROLE_PUBLICACAO_MOBILE = "ROLE_PUBLICACAO_MOBILE",
  ROLE_PUBLICACAO = "ROLE_PUBLICACAO",
  ROLE_BIBLIOTECA = "ROLE_BIBLIOTECA",
  ROLE_PECA_ARQ_MOBILE = "ROLE_PECA_ARQ_MOBILE",
  ROLE_PECA_ARQUITETURA = "ROLE_PECA_ARQUITETURA",
  ROLE_NOMINATA_IRMAO = "ROLE_NOMINATA_IRMAO",
  ROLE_FILIANDO = "ROLE_FILIANDO",
  ROLE_CONVITE_FILIACAO = "ROLE_CONVITE_FILIACAO",
  ROLE_RESPOSTA_SMS = "ROLE_RESPOSTA_SMS",
  ROLE_MINHAS_NOTIFICACOES = "ROLE_MINHAS_NOTIFICACOES",
  ROLE_NOTIFICACAO = "ROLE_NOTIFICACAO",
  ROLE_PAINEL_CONTROLE = "ROLE_PAINEL_CONTROLE",
  ROLE_TURMA = "ROLE_TURMA",
  ROLE_BALAUSTRE_HISTORICO = "ROLE_BALAUSTRE_HISTORICO",
  ROLE_MINHAS_COBRANCAS = "ROLE_MINHAS_COBRANCAS",
  ROLE_MINHA_FICHA = "ROLE_MINHA_FICHA",
  ROLE_COBRANCA = "ROLE_COBRANCA",
  ROLE_SINDICANTE = "ROLE_SINDICANTE",
  ROLE_PROFANO = "ROLE_PROFANO",
  ROLE_CONVITE = "ROLE_CONVITE",
  ROLE_ESCRUTINIO = "ROLE_ESCRUTINIO",
  ROLE_FICHA_MOBILE = "ROLE_FICHA_MOBILE",
  ROLE_TESOURARIA = "ROLE_TESOURARIA",
  ROLE_ACESSO = "ROLE_ACESSO",
  ROLE_CADASTRO = "ROLE_CADASTRO",
  ROLE_IRMAO = "ROLE_IRMAO",
  ROLE_USUARIO = "ROLE_USUARIO",
}

export default RolesEnum;
