/* eslint-disable no-unused-vars */
import { Tag } from "primereact/tag";
import React from "react";
import { SituacaoIrmao } from "../../pages/Irmao/IrmaoPage";

type IrmaoSituacaoComponentProps = {
  situacao: SituacaoIrmao;
};

export interface SituacaoIrmaoElement {
  label: string;
  class: string;
  situacao: SituacaoIrmao;
}
type SituacaoMap = {
  [name in SituacaoIrmao]: SituacaoIrmaoElement;
};

export const situacoesMap: SituacaoMap = {
  DESLIGADO: { label: "Desligado", class: "situacao-irmao-desligado", situacao: "DESLIGADO" },
  EX_OFICIO: { label: "Ex oficio", class: "situacao-irmao-ex-oficio", situacao: "EX_OFICIO" },
  FALECIDO: { label: "Falecido", class: "situacao-irmao-falecido", situacao: "FALECIDO" },
  IRREGULAR: { label: "Irregular", class: "situacao-irmao-irregular", situacao: "IRREGULAR" },
  REGULAR: { label: "Regular", class: "situacao-irmao-regular", situacao: "REGULAR" },
  // REMIDO: { label: "Remido", class: "situacao-irmao-remido", situacao: "REMIDO" },
  ADORMECIDO: { label: "Adormecido", class: "situacao-irmao-adormecido", situacao: "ADORMECIDO" },
  SUSPENSO: { label: "Suspenso", class: "situacao-irmao-suspenso", situacao: "SUSPENSO" },
};

const IrmaoSituacaoComponent: React.FC<IrmaoSituacaoComponentProps> = ({ situacao }) => {
  return (
    <Tag className={`${situacoesMap[situacao].class}`} value={situacoesMap[situacao].label} style={{ width: "80px" }} />
  );
};

export default IrmaoSituacaoComponent;
