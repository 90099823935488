import React, { useCallback } from "react";
import { Dependente } from "../ProfanoDependentes";

interface Props {
  dependente: Dependente;
  // eslint-disable-next-line no-unused-vars
  onDelete: (id: string) => void;
}

const DependenteCard: React.FC<Props> = ({ dependente, onDelete }) => {
  const handleDeleteButton = useCallback(() => {
    onDelete(dependente.id);
  }, [dependente.id, onDelete]);

  return (
    <div className="p-d-flex p-jc-between p-m-2 p-pl-2 p-shadow-2 dependente-card">
      <div className="p-d-flex p-flex-column p-jc-center p-jc-center p-pt-2 p-pb-2">
        <span className="p-mb-2">Nome: {dependente?.nome}</span>
        <span className="p-mb-2">Parentesco: {dependente?.parentesco}</span>
        <span>
          Data de nascimento:{" "}
          {dependente.dtNascimento !== undefined &&
            !Number.isNaN(dependente.dtNascimento?.getDate()!) &&
            Intl.DateTimeFormat("pt-BR").format(dependente.dtNascimento!)}
        </span>
      </div>
      <button className="trash-icon-button" type="button" onClick={handleDeleteButton}>
        <i className="pi pi-trash" />
      </button>
    </div>
  );
};

export default DependenteCard;
