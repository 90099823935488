/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { v4 } from "uuid";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import SimpleEntityDatatable from "../SimpleEntityDatatable";
import SimpleEntityListPage from "../SimpleEntityPage";
import DependenteCard from "../DependenteCard";

export interface Dependente {
  id: string;
  parentesco: string;
  nome: string;
  dtNascimento: Date | undefined;
}

interface ProfanoDependentesProps {
  dependentes?: Dependente[];
  onAdd: (dependente: Dependente) => void;
  onRemove: (dependenteId: string) => void;
  onUpdate: (dependenteId: string, dependente: Dependente) => void;
  useMobileComponents?: boolean;
}

type Parentesco = "Filho" | "Filha" | "Cônjuge";

const ProfanoDependentes: React.FC<ProfanoDependentesProps> = ({
  dependentes = [],
  onAdd,
  onRemove,
  onUpdate,
  useMobileComponents,
}) => {
  const dt = useRef<DataTable>(null);
  const parentescoOptions: Parentesco[] = useMemo(() => {
    return ["Filho", "Filha", "Cônjuge"];
  }, []);

  const handleAddDependente = useCallback((dependente: Dependente) => onAdd(dependente), [onAdd]);

  const handleRemoveDependente = useCallback((dependenteId: string) => onRemove(dependenteId), [onRemove]);

  const handleUpdateDependente = useCallback(
    (dependenteId: string, dependente: Dependente) => onUpdate(dependenteId, dependente),
    [onUpdate]
  );

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const dtNascimentoBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <Calendar
          key={rowData.id}
          placeholder="Selecione ou digite"
          value={rowData.dtNascimento}
          onChange={(e) => {
            const dependente = { ...rowData };
            if (e.value && e.value instanceof Date) {
              dependente.dtNascimento = e.value;
              handleUpdateDependente(rowData.id, dependente);
            }
          }}
          monthNavigator
          yearNavigator
          yearRange="1800:2050"
          monthNavigatorTemplate={monthNavigatorTemplate}
          yearNavigatorTemplate={yearNavigatorTemplate}
          locale="pt-br"
          showIcon
          maxDate={new Date(Date.now())}
          mask="99/99/9999"
        />
      );
    },
    [handleUpdateDependente]
  );

  const nomeBodyTemplate = useCallback(
    (rowData: Dependente) => {
      const index = dependentes.findIndex((dependente) => dependente.id === rowData.id);
      return (
        <InputText
          key={rowData.id}
          placeholder=""
          value={rowData.nome}
          autoFocus={index === 0}
          onChange={(e) => {
            const dependente = { ...rowData };
            if (e.target.value) {
              dependente.nome = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }
          }}
        />
      );
    },
    [dependentes, handleUpdateDependente]
  );

  const parentescoBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <Dropdown
          key={rowData.id}
          value={rowData.parentesco}
          emptyMessage="Nenhum parentesco encontrado."
          placeholder="Selecione"
          options={parentescoOptions}
          onChange={(e) => {
            const dependente = { ...rowData };
            if (e.target.value) {
              dependente.parentesco = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }
          }}
        />
      );
    },
    [handleUpdateDependente, parentescoOptions]
  );

  const deleteButtonBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <>
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              handleRemoveDependente(rowData.id);
            }}
          />
        </>
      );
    },
    [handleRemoveDependente]
  );

  const columnsDependentes = useMemo(
    (): ColumnProps[] => [
      {
        field: "parentesco",
        header: "Parentesco",
        body: parentescoBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "20%" },
      },
      {
        field: "nome",
        header: "Nome completo",
        body: nomeBodyTemplate,
        bodyClassName: "p-fluid",
      },
      {
        field: "dtNascimento",
        header: "Nascimento",
        body: dtNascimentoBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "20%" },
      },

      {
        body: deleteButtonBodyTemplate,
        align: "center",
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [deleteButtonBodyTemplate, dtNascimentoBodyTemplate, nomeBodyTemplate, parentescoBodyTemplate]
  );

  const [showMobileDialog, setShowMobileDialog] = useState<boolean>(false);

  const [parentescoMobile, setParentescoMobile] = useState<string>("");
  const [nomeMobile, setNomeMobile] = useState<string>("");
  const [dtNascimentoMobile, setDtNascimentoMobile] = useState<Date | undefined>(undefined);

  const onHideDependenteDialog = useCallback(() => {
    setShowMobileDialog(false);
    setParentescoMobile("");
    setNomeMobile("");
    setDtNascimentoMobile(undefined);
  }, []);

  const [errors, setErrors] = useState<{ [errorKey: string]: string }>({});

  const validFields = useCallback(() => {
    let parentescoError = "";
    let nomeError = "";
    let dtNascimentoError = "";

    if (_.isEmpty(parentescoMobile)) parentescoError = "Campo obrigatório";
    if (_.isEmpty(nomeMobile)) nomeError = "Campo obrigatório";
    if (!dtNascimentoMobile || Number.isNaN(dtNascimentoMobile.getTime())) dtNascimentoError = "Campo obrigatório";

    setErrors({
      parentesco: parentescoError,
      nome: nomeError,
      dtNascimento: dtNascimentoError,
    });

    return _.isEmpty(parentescoError) && _.isEmpty(nomeError) && _.isEmpty(dtNascimentoError);
  }, [dtNascimentoMobile, nomeMobile, parentescoMobile]);

  const handleSaveMobileDependente = useCallback(() => {
    const isValid = validFields();
    if (isValid) {
      handleAddDependente({
        id: v4(),
        nome: nomeMobile,
        parentesco: parentescoMobile,
        dtNascimento: dtNascimentoMobile,
      });
      onHideDependenteDialog();
    }
  }, [dtNascimentoMobile, handleAddDependente, nomeMobile, onHideDependenteDialog, parentescoMobile, validFields]);

  const dependenteDialogFooter = useCallback(
    () => (
      <>
        <Button icon="pi pi-times" label="Cancelar" onClick={onHideDependenteDialog} className="p-button-text" />
        <Button icon="pi pi-check" label="Adicionar" onClick={handleSaveMobileDependente} />
      </>
    ),
    [handleSaveMobileDependente, onHideDependenteDialog]
  );

  return (
    <>
      <Dialog
        header="Adicionar dependente"
        visible={showMobileDialog}
        modal
        footer={dependenteDialogFooter()}
        onHide={onHideDependenteDialog}
        className="confirm-dialog-container"
        closable
        style={{ maxWidth: "600px", width: "100%" }}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="parentesco">Parentesco</label>
            <Dropdown
              id="parentesco"
              value={parentescoMobile}
              options={parentescoOptions.map((parentesco) => ({ label: parentesco, value: parentesco }))}
              placeholder="Selecione"
              onChange={(e) => {
                setParentescoMobile(e.value);
              }}
            />
            <div className="custom-vertical-form-field-error-container p-d-flex p-jc-start p-mt-2 p-ai-center">
              {errors.parentesco && <i className="fas fa-exclamation-triangle p-mr-2 p-error" />}
              <small className="p-error p-mt-0 custom-vertical-form-field-error">{errors.parentesco || ""}</small>
            </div>
          </div>

          <div className="p-field">
            <label htmlFor="nome">Nome completo</label>
            <InputText
              id="nome"
              placeholder="Digite o nome do seu dependente"
              value={nomeMobile}
              onChange={(e) => {
                setNomeMobile(e.target.value);
              }}
            />
            <div className="custom-vertical-form-field-error-container p-d-flex p-jc-start p-mt-2 p-ai-center">
              {errors.nome && <i className="fas fa-exclamation-triangle p-mr-2 p-error" />}
              <small className="p-error p-mt-0 custom-vertical-form-field-error">{errors.nome || ""}</small>
            </div>
          </div>

          <div className="p-field">
            <label htmlFor="dtNascimento">Data de nascimento</label>
            <Calendar
              id="dtNascimento"
              placeholder="Selecione ou digite"
              value={dtNascimentoMobile}
              onChange={(e) => {
                if (e.value && e.value instanceof Date) {
                  setDtNascimentoMobile(e.value);
                }
              }}
              monthNavigator
              yearNavigator
              yearRange="1800:2050"
              monthNavigatorTemplate={monthNavigatorTemplate}
              yearNavigatorTemplate={yearNavigatorTemplate}
              locale="pt-br"
              showIcon
              maxDate={new Date(Date.now())}
              mask="99/99/9999"
            />
            <div className="custom-vertical-form-field-error-container p-d-flex p-jc-start p-mt-2 p-ai-center">
              {errors.dtNascimento && <i className="fas fa-exclamation-triangle p-mr-2 p-error" />}
              <small className="p-error p-mt-0 custom-vertical-form-field-error">{errors.dtNascimento || ""}</small>
            </div>
          </div>
        </div>
      </Dialog>
      <SimpleEntityListPage
        showListHeader
        newButtonLabel="Adicionar dependente"
        newButtonIcon="fas fa-user-plus"
        onNewButtonCLick={() => {
          if (!useMobileComponents)
            handleAddDependente({ id: v4(), nome: "", parentesco: "", dtNascimento: undefined });
          else {
            setShowMobileDialog(true);
          }
        }}
        datatableRef={dt}
      >
        {!useMobileComponents && (
          <SimpleEntityDatatable
            datatableRef={dt}
            value={dependentes}
            columns={columnsDependentes}
            paginator={false}
            emptyMessage="Nenhum dependente"
            rows={undefined}
          />
        )}
        {useMobileComponents && (
          <div className="p-d-flex p-flex-column">
            {dependentes?.map((dependente) => (
              <DependenteCard key={dependente.id} dependente={dependente} onDelete={handleRemoveDependente} />
            ))}
          </div>
        )}
      </SimpleEntityListPage>
    </>
  );
};

ProfanoDependentes.defaultProps = {
  dependentes: [],
  useMobileComponents: false,
};

export default ProfanoDependentes;
